import React from 'react'
import { graphql } from 'gatsby'
import BlockContent from '../components/block-content'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import PeopleGrid from '../components/people-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Background from '../components/background'

import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'

import styles from '../components/contact-form.module.css'
import david from '../assets/david-headshot.jpg'
import NoovelVideo from '../assets/Noovel_Video.mp4'
import NoovelPoster from '../assets/noovel-thumbnail.jpg'

export const query = graphql`
  query AboutPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)about/" }) {
      id
      title
      _rawBody
    }
    people: allSanityPerson {
      edges {
        node {
          id
          image {
            asset {
              _id
            }
          }
          name
          _rawBio
        }
      }
    }
  }
`

const AboutPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data && data.page
  const personNodes =
    data && data.people && mapEdgesToNodes(data.people).filter(filterOutDocsWithoutSlugs)

  if (!page) {
    throw new Error(
      'Missing "About" page data. Open the studio at http://localhost:3333 and add "About" page data and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={page.title} />
      <Container>
      <Background />
        <video className={styles.video} controls poster={NoovelPoster}>
          <source src={NoovelVideo} type="video/mp4" />
        </video>
        <div className={styles.david}>
            <img src={david} className={styles.headshot} alt="David Headshot" />
            <div className={styles.contactInfo}>
              David Abehsera<br />
              Founder, Chief Consultant <br />
              <a className={styles.link} href="mailto:david@Noovel.co">david@noovel.co</a>
            </div>
        </div>
        <BlockContent blocks={page._rawBody || []} />
        {personNodes && personNodes.length > 0 && <PeopleGrid items={personNodes} title='People' />}
      </Container>
    </Layout>
  )
}

export default AboutPage
